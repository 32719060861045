import { Next } from '@securitize/ui-components';

export const GET_OPPORTUNITY_NEXT = (opportunityId) => new Next(`/opportunities/${opportunityId}`, 'GET_OPPORTUNITY_NEXT');

export const UPDATE_OPPORTUNITY_NEXT = (opportunityId) => new Next(`/opportunities/${opportunityId}`,
  'UPDATE_OPPORTUNITY_NEXT',
  { method: 'PATCH' });

// eslint-disable-next-line
export const GET_OPPORTUNITY_DOCUMENT_URL_NEXT = (opportunityId, documentId) => {
  return new Next(`/opportunities/${opportunityId}/document/${documentId}`, 'GET_OPPORTUNITY_DOCUMENT_URL_NEXT');
};

export const GET_OPPORTUNITY_BROKER_DEALER_NEXT = (opportunityId) => new Next(
  `/opportunities/${opportunityId}/broker-dealer`, 'GET_OPPORTUNITY_BROKER_DEALER_NEXT',
);
