import {
  GET_OPPORTUNITY_BROKER_DEALER_NEXT as brokerDealerNext,
  GET_OPPORTUNITY_NEXT as next,
  UPDATE_OPPORTUNITY_NEXT as updateNext,
} from './actionTypes';

const defaultState = {
  isLoading: false,
  isUpdateLoading: false,
  isError: false,
  data: {},
  opportunityIsBrokerDealer: false,
};
const GET_OPPORTUNITY_NEXT = next();
const UPDATE_OPPORTUNITY_NEXT = updateNext();
const GET_OPPORTUNITY_BROKER_DEALER_NEXT = brokerDealerNext();

export const opportunity = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OPPORTUNITY_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_OPPORTUNITY_NEXT.INIT:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case UPDATE_OPPORTUNITY_NEXT.ERROR:
      return {
        ...state,
        isUpdateLoading: false,
        isError: true,
      };

    case GET_OPPORTUNITY_NEXT.SUCCESS:
    case UPDATE_OPPORTUNITY_NEXT.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
        isUpdateLoading: false,
      };

    case GET_OPPORTUNITY_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_OPPORTUNITY_BROKER_DEALER_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OPPORTUNITY_BROKER_DEALER_NEXT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        opportunityIsBrokerDealer: payload.isBrokerDealer,
      };

    default:
      return state;
  }
};
