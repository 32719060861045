import { authoriseWalletNext, GET_WALLETS_NEXT, SAVE_TEMP_AUTH_WALLET_DATA } from './actionTypes';
import { getInvestorPortfolioNext } from '../../actionTypes';

const defaultState = {
  addWalletIsLoading: false,
  addWalletIsError: false,
  errorType: '',
  tempAuthWalletData: {},
  data: [],
  isLoading: false,
  isError: false,
};

export const wallets = (state = defaultState, { type, payload }) => {
  switch (type) {
    case authoriseWalletNext.INIT:
      return {
        ...state,
        addWalletIsError: false,
        addWalletIsLoading: true,
      };
    case authoriseWalletNext.SUCCESS:
      return {
        ...state,
        data: payload,
        addWalletIsLoading: false,
        errorType: '',
        addWalletIsError: false,
      };

    case authoriseWalletNext.ERROR:
      return {
        ...state,
        addWalletIsLoading: false,
        addWalletIsError: true,
        errorType: payload.message,
      };
    case SAVE_TEMP_AUTH_WALLET_DATA:
      return {
        ...state,
        tempAuthWalletData: payload,
      };


    case GET_WALLETS_NEXT.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case GET_WALLETS_NEXT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
      };
    case GET_WALLETS_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case getInvestorPortfolioNext.SUCCESS:
      return {
        ...state,
        wallets: payload.tokenWallets,
        walletsLoading: false,
        isError: false,
      };

    default:
      return state;
  }
};
